<template>
  <button
    type="button"
    class="flex items-center gap-1 whitespace-nowrap rounded-lg border border-fivefold px-3 py-2 text-sm font-medium shadow-card_primary hover:shadow-card_primary_hover"
    :class="{ 'gradient-card': !inactive && value }"
  >
    <UiIcon name="calendar" class="mr-1 size-5" />
    <template v-if="!inactive && value">
      <template v-if="Array.isArray(value)">
        <UiDate :value="value[0]" template="dd MMM" /> —
        <UiDate :value="value[1]" template="dd MMM" />
      </template>
      <template v-else>
        <UiDate :value="value" template="dd MMM" />
      </template>
      <UiIcon name="x-mark" class="size-5" @click.stop="emit('reset')" />
    </template>
    <template v-else> выберите когда </template>
  </button>
</template>

<script setup lang="ts">
import UiDate from '../UiDate.vue'
import UiIcon from '../UiIcon.vue'

type PropType = {
  value?: Date | Date[]
  inactive?: boolean
}
type EmitType = {
  (e: 'reset'): void
}

withDefaults(defineProps<PropType>(), {
  value: undefined,
  inactive: false
})
const emit = defineEmits<EmitType>()
</script>
