<template>
  <Component
    :is="tag"
    :type="type"
    :class="[
      'group rounded-[100px] font-normal transition-colors',
      { '!bg-transparent': transparent },
      computedClasses
    ]"
    :disabled="disabled"
    @click="!loading && !disabled && emit('click', $event)"
  >
    <span v-if="loading" class="absolute inset-center">
      <img src="@/assets/icons/outline/loader.svg" alt="loading" class="animate-spin" />
    </span>
    <slot />
    <span
      v-if="link"
      class="inline-flex items-center transition-transform group-hover:translate-x-0.5"
    >
      &nbsp;<slot name="link-icon">
        <UiIcon name="arrow-right" class="inline-flex size-3" />
      </slot>
    </span>
  </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UiIcon from './UiIcon.vue'

type PropsType = {
  severity?: 'primary' | 'secondary' | 'tertiary' | 'gradient'
  size?: 'big' | 'small'
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  icon?: boolean
  text?: boolean
  disabled?: boolean
  transparent?: boolean
  tag?: string
  link?: boolean
}

type EmitType = {
  (e: 'click', event: MouseEvent): void
}

const props = withDefaults(defineProps<PropsType>(), {
  severity: 'primary',
  size: 'big',
  type: 'button',
  transparent: false,
  disabled: false,
  tag: 'button',
  link: false
})
const emit = defineEmits<EmitType>()

const severityClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: 'bg-button-primary hover:bg-button-primary-hover text-button-primary-text',
  secondary: 'bg-button-secondary hover:bg-button-secondary-hover text-button-secondary-text',
  tertiary: 'bg-button-tertiary hover:bg-button-tertiary-hover text-button-tertiary-text',
  gradient:
    'gradient-background transition-all hover:shadow-card_primary relative text-text-primary border border-fivefold'
}

const sizeClassesMap: Record<NonNullable<PropsType['size']>, string> = {
  big: 'p-4 text-base',
  small: 'py-2 px-3 text-xs'
}

const defaultTextClasses = '!bg-transparent !p-0 '
const textClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: `${defaultTextClasses} text-text-main`,
  secondary: `${defaultTextClasses} text-text-secondary`,
  tertiary: `${defaultTextClasses} text-text-tertiary`,
  gradient: `${defaultTextClasses} text-text-main`
}

const defaultLoadingClasses = 'text-transparent'
const loadingClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: `${defaultLoadingClasses} ${severityClassesMap['primary']} hover:!bg-button-primary`,
  secondary: `${defaultLoadingClasses} ${severityClassesMap['secondary']} hover:!bg-button-secondary`,
  tertiary: `${defaultLoadingClasses} ${severityClassesMap['tertiary']} hover:!bg-button-tertiary`,
  gradient: `${defaultLoadingClasses} ${severityClassesMap['tertiary']}`
}

const defaultIconClasses = 'flex items-center justify-center rounded-full !p-1 text-none'

const disabledClassesMap: Record<NonNullable<PropsType['severity']>, string> = {
  primary: '!bg-button-primary-disabled',
  secondary: '!bg-button-secondary-disabled',
  tertiary: '!bg-button-tertiary-disabled disabled:opacity-70',
  gradient: 'opacity-70'
}

const linkClasses: Record<NonNullable<PropsType['severity']>, string> = {
  primary:
    'font-medium text-sm transition-colors !text-button-primary-link hover:!text-button-primary-link-hover focus:!text-button-primary-link-hover',
  secondary:
    'font-medium text-sm transition-colors !text-button-secondary-link hover:!text-button-secondary-link-hover focus:!text-button-secondary-link-hover',
  tertiary:
    'font-medium text-sm transition-colors !text-button-secondary-link hover:!text-button-secondary-link-hover focus:!text-button-secondary-link-hover',
  gradient:
    'font-medium text-sm transition-colors !text-button-primary-link hover:!text-button-primary-link-hover focus:!text-button-primary-link-hover'
}

const computedClasses = computed(() =>
  [
    severityClassesMap[props.severity],
    sizeClassesMap[props.size],
    props.text ? textClassesMap[props.severity] : '',
    props.loading ? loadingClassesMap[props.severity] : '',
    props.icon ? defaultIconClasses : '',
    props.disabled ? disabledClassesMap[props.severity] : '',
    props.link ? linkClasses[props.severity] : '',
    props.disabled && props.link ? '!text-text-quaternary' : ''
  ].join(' ')
)
</script>

<style scoped>
.gradient-background {
  background-image: linear-gradient(
    to bottom,
    rgb(from var(--colors-gradient-primary-from) r g b / 50%) 3%,
    rgb(from var(--colors-gradient-primary-to) r g b / 50%) 98%
  );
}
</style>
